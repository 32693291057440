(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name users.edit.controller:UserEditCtrl
   *
   * @description
   *
   */
  angular
    .module('users.edit')
    .controller('UserEditCtrl', UserEditCtrl);

  function UserEditCtrl($state, $stateParams, $window, Restangular) {
    var vm = this;

    vm.user = {};
    vm.isEditing = $state.is('settings.users.edit');
    vm.errors = [];
    vm.passwordResetLink = $window.location.origin + '/#/login/forgotten';
    vm.accesses = [
        {
            code: 'regular',
            name: 'Read only'
        },
        {
            code: 'admin',
            name: 'Admin'
        }
    ];

    vm.submit = submit;

    if (vm.isEditing) {
      Restangular.one('users', $stateParams.userId).get().then(function (response) {
        vm.user = response.data;
      });
    }

    // Save user
    function submit() {
      if (vm.isEditing) {
        Restangular.all('users').customPUT(vm.user, vm.user.id).then(submitSuccess, submitFailure);
      } else {
        Restangular.all('users').post(vm.user).then(submitSuccess, submitFailure);
      }
    }

    function submitSuccess(response) {
      $state.go('settings.users.list');
    }

    function submitFailure(response) {
      console.log('user submit failed', response.data.errors);
      vm.errors = response.data.errors;
    }
  }
}());
